import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function Promo() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const [bank, setBank] = useState(null);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    const onSubmit = (data) => {
        if (data.code == "") {
            swal("Thông báo", "Vui lòng nhập đầy đủ thông tin", "warning");
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/promo/active`, { code: data.code })
            .then((res) => {
                swal("Thành Công", `Mã khuyến mãi đã được áp dụng thành công! Bạn đã nhận được ${res.data.data.money.toLocaleString("vi-VN")}.`, "success").then(() =>
                    window.location.reload()
                );
                axios
                    .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                    .then((res) => {
                        if (profile != res.data.data) {
                            setProfile(res.data.data);
                            localStorage.setItem("profile", JSON.stringify(res.data.data));
                        }
                    })
                    .catch((err) => localStorage.removeItem("user"));
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error"));
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Nhập mã khuyến mãi</h1>
            </div>
            <div className="main">
                <div className="content_profile">
                    <AnimatedPage>
                        <form className="form-lg form-box form-code" onSubmit={handleSubmit(onSubmit)} style={{marginTop:"20px"}}>
                            <div className="inputs">
                                <div className="input">
                                    <label>Nhập mã khuyến mãi</label>
                                    <input
                                        className="ipadd"
                                        type="text"
                                        placeholder="Nhập mã khuyến mãi"
                                        {...register("code", {
                                            required: true
                                        })}
                                    />
                                </div>
                                <button type="submit" className="btn-submit">
                                    Xác nhận
                                </button>
                            </div>
                        </form>
                    </AnimatedPage>
                </div>

                <Footer />
            </div>
        </>
    );
}
export default Promo;
